<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear indeterminate color="primary" class="mb-4"></v-progress-linear>
      <v-skeleton-loader v-bind="attrs" type="table-heading, article"></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line, table-tfoot"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line, table-tfoot"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line, table-tfoot"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line, table-tfoot"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="table-heading, list-item-two-line,  list-item-two-line,  list-item-two-line"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
}
</script>
